<script setup lang="ts">
const { headerSnackbars, deleteSnackbar } = useHeaderSnackbars()
const { getSnackBarProps } = useHeaderSnackbarMessage()
</script>
<template>
  <HeadlessPortal v-if="headerSnackbars.size > 0">
    <UtilsSnackbarGroupWrapper
      class="positive-margin gap-y-md fixed left-0 right-0 top-[84px] z-[99] flex flex-col md:top-[104px] lg:top-[112px] xl:top-[132px]"
    >
      <UtilsSnackbar
        v-for="[key, snackbar] in headerSnackbars"
        :key="key"
        v-bind="getSnackBarProps(snackbar.data)"
        :duration="snackbar.data.duration ?? 0"
        is-feedback
        @update:close="deleteSnackbar(key)"
      />
    </UtilsSnackbarGroupWrapper>
  </HeadlessPortal>
</template>
